var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"title":"Documents","errorMessage":_vm.errorMessage,"topActionButtonPrimary":_vm.primaryButton,"topActionButtonSecondary":_vm.secondaryButton},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest || _vm.loading),expression:"makingApiRequest || loading"}],attrs:{"element-loading-text":_vm.loadingText,"data":_vm.atlasfiles,"paginationLinks":_vm.links,"totalRecords":_vm.getTotalRecords,"tableActions":_vm.tableActions,"tableColumns":_vm.$getCurrentUser && _vm.$getCurrentUser.role === 'admin'
        ? _vm.tableColumns
        : _vm.agentsTableColumns,"filters":_vm.filters,"tableRowActions":_vm.tableRowActions},on:{"fetch-query":_vm.fetchPageData,"table-row-action":_vm.handleResponseAction,"tableRowClicked":_vm.downloadFile}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Documents Found."},slot:"notFound"})],1),_c('ModalBase',{attrs:{"name":"upload-document-modal","size":"large","loading":_vm.makingApiRequest,"clickToClose":false,"showClose":true,"title":"Upload Documents"}},[_c('UploadArea',{attrs:{"acceptMultiple":false,"uploadLimit":1,"uploadType":"document","canChangeUploadType":false,"showUploadType":false,"showUploadConfigs":true,"autoUpload":false,"showHeading":false,"categoryOptions":_vm.categoryFilterOptions},on:{"fileUploadSuccess":_vm.onFileUploadSuccess,"fileUploadError":_vm.onFileUploadError}})],1),_c('ModalBase',{attrs:{"name":"edit-file-modal","size":"medium","clickToClose":false,"showClose":true}},[_c('AtlasFileEdit',{attrs:{"categoryOptions":_vm.categoryFilterOptions},on:{"close":function($event){_vm.showAtlasFileEditModal = false}}})],1),_c('ModalMessage',{attrs:{"name":"atlasFilesDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":`This will delete the document: ${_vm.documentTitle}. Continue?`,"showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneAtlasFile()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('atlasFilesDeleteModal')
      }
    ]}}),_c('ModalBase',{attrs:{"name":"selectCategoryModal","size":"medium","title":"Please select a category","clickToClose":true,"showClose":true}},[_c('AtlasFileSelectCategory',{attrs:{"categoryOptions":_vm.categoryFilterOptions},on:{"close":function($event){return _vm.$modal.hide('selectCategoryModal')},"categorySelected":_vm.setCategory}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }